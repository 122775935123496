$blue: #54616F;
$blue: #08415C;
$red: darken(#C67366, 10);
$red: #8e322b;
$gray:  #5D6167;
// $gray: #A7A2A9;
$black: #212325;

$orange: #ED955D;
$green: #BBC530;

$light-gray: #E6E6E4;
$light-gray: #E5EBEA;

$white: #fff;

$box-shadow: 6px 6px 0 rgba(#000, 0.15);

$border-thickness-sm: 4px;
$border-thickness-lg: 10px;

$border-radius: 5px;

$lg: 992px;

$content-width: 1350px;
$content-width-padding: calc((100vw - #{$content-width})/2);
$contact-width: 700px;

$content-padding: 35px;
$card-padding: 30px;

$banner-height: 200px;
$banner-offset: 80px;

$negative-content-padding: calc(#{$content-padding} * -1);
$negative-content-width-padding: calc(#{$content-width-padding} * -1);

$header-height-xs: 88px;
$header-height: 200px;