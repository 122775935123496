.modal-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 500;
}

.modal-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 700;
  width: inherit;
  outline: 0;
  max-height: 100vh;
  overflow-y: auto;

  .modal {
    z-index: 100;
    background: white;
    position: relative;
    margin: auto;
    width: 800px;
    max-width: calc(100vw - 60px);
    padding: 30px;

    .modal-header {
      border-radius: 8px 8px 0 0;
      display: flex;
      justify-content: space-between;
      // padding-bottom: 30px;
      margin-top: 0;
      align-items: center;
    }

    .header-text {
      color: #fff;
      align-self: center;
      color: #000;

      margin: 0;
    }

    .close-btn {
      font-size: 26px;
      border: none;
      border-radius: 3px;
      margin-left: 0.5rem;
      background: none;
      padding-right: 0;
      :hover {
        cursor: pointer;
      }
    }

    a {
      color: $red;
      text-decoration: none;

      &:hover {
          text-decoration: underline;
          color: lighten($red, 10);
      }
    }

    .content {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}
