.services {
  margin-bottom: 50px;

  overflow-y: hidden;
  overflow-x: auto;
  scrollbar-width: none;
  --ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  margin: 0 $negative-content-padding;

  .service-cards {
    display: flex;
    justify-content: space-between;
    width: fit-content;
    overflow-y: hidden;
    min-width: calc(100% - 2 * #{$content-padding});
    padding: 0 $content-padding 40px $content-padding;

    background: linear-gradient(
      180deg,
      transparent 0,
      transparent 50%,
      $blue 0,
      $blue 100%
    );

    .service-card {
      @include card(#fff);
      border-color: $light-gray;
      min-width: 230px;
      max-width: 300px;
      margin-right: 20px;
      height: 87%;

      h2 {
        color: $gray;
        text-align: center;
      }

      .service-items {
        background: $red;
        margin: 0 calc(-1 * #{$card-padding});
        padding: 10px;
      }

      .service-tools {
        text-align: center;
        h3 {
          color: $gray;
        }

        .tag-wrapper {
          margin: 0 calc(-1 * #{$card-padding});

          .tag {
            border: 1px solid $red;
            background: rgba($red, 0.4);
            color: darken($gray, 30);
            border-radius: $border-radius;
            padding: 3px 10px;
            margin: 5px;
            display: inline-block;
          }
        }
        color: $gray;
      }
    }
  }

  @media screen and (min-width: $content-width) {
    .service-cards {
      justify-content: center;
    }
  }
}
