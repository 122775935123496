.about-me {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 50px;

  .portrait-wrapper {
    width: 75vw;
    overflow-x: hidden;
    margin-left: $negative-content-padding;
    max-width: 400px;

    .portrait {
      width: 100vw;
      max-width: 400px;
      height: auto;
      margin-left: -20px;
    }
  }

  .about-me-card {
    @include card($red);
    width: 30vw;
    // float: right;
  }

  @media screen and (min-width: 768px) {
    //padding: 0 $content-padding;
    margin: 0 auto;
    justify-content: center;

    .portrait-wrapper {
      width: auto;
      margin-left: $negative-content-padding;
      border: $border-thickness-sm solid #fff;
      box-shadow: $box-shadow;
      border-bottom: 0;
      margin-right: -50px;
  
      .portrait {
        width: auto;
        margin: 0px;
        max-width: 500px;
      }
    }
    .about-me-card {
      width: 600px;
      margin-left: -30px;
    }
  }
}
