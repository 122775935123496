.top-banner {
  margin-bottom: 50px;

  .background-image {
    margin: 0 $negative-content-padding;
    transform: translateY(#{$banner-offset});
    height: $banner-height;
    overflow: hidden;

    img {
      width: 100vw;
      height: auto;
    }
  }

  .background-overlay {
    margin: 0 $negative-content-padding;
    transform: translateY(calc(-1 * #{$banner-height} + #{$banner-offset}));
    height: $banner-height;

    background: rgba($blue, 0.7);
  }

  .banner-card {
    @include card($blue);
    max-width: $content-width;
    transform: translateX(calc(50vw - #{$content-padding})) translateX(-50%);
    margin-top: calc(-2 * #{$banner-height});

    padding: 50px 60px;

    p {
      font-size: 26px;
    }

    h1,
    .button-wrapper {
      text-align: center;
    }

    .button-wrapper {
      button {
        &:hover {
          color: $blue;
        }
        &:first-child {
          margin-bottom: 20px;
        }
      }
    }
  }

  @media screen and (min-width: $content-width) {
    .banner-card {
      padding: 70px;

      h1 {
        font-size: 70px;
      }

      p {
        text-align: center;
      }

      .button-wrapper button {
        font-size: 26px;

        &:first-child {
          margin-right: 40px;
        }
      }

      margin-bottom: 250px;
    }

    @include banner-height-offset(350px, 180px, 0);
  }

  @media screen and (min-width: 1400px) {
    @include banner-height-offset(380px, 180px, 0);
  }

  @media screen and (min-width: 1600px) {
    @include banner-height-offset(350px, 200px, -8%);
  }

  @media screen and (min-width: 1850px) {
    @include banner-height-offset(380px, 200px, -10%);
  }

  @media screen and (min-width: 2200px) {
    @include banner-height-offset(380px, 200px, -15%);
  }

  @media screen and (min-width: 2900px) {
    @include banner-height-offset(450px, 150px, -15%);
  }
}
