.footer {
  background: $light-gray;
  // margin: 80px $negative-content-padding 50px $negative-content-padding;
  padding: 10px $content-padding;
  position: absolute;
  width: calc(100% - #{$content-padding} * 2);
  z-index: -2;
  left: 0;


  @media screen and (min-width: $contact-width) {
  > div {
    max-width: calc(#{$contact-width} + 60px);
    margin: auto
  }}

  i {
    font-size: 12px;
    transform: translateY(-2px);
    margin: 0 5px;
    color: $red;
  }

  button {
    padding: 0;
    border: none;
    color: $gray;
    transition: none;
    
    &:last-child {
      margin-left: 10px;
    }

    &:hover,
    &:active,
    &:focus {
      color: $red;
      background: transparent;
      transition: none;
    }
  }
}
