.portfolio {
  margin-top: 40px;
}

.portfolio-item {
  margin: $negative-content-padding;
  padding: $content-padding;

  @media screen and (min-width: 769px) {
    &:nth-child(2),
    &:nth-child(5) {
      @include portfolio-card-lg($blue);
      margin-top: 150px;
    }
    &:nth-child(3),
    &:nth-child(6) {
      @include portfolio-card-lg($gray);
    }
    &:nth-child(4) {
      @include portfolio-card-lg($red);
    }
  }

  @media screen and (max-width: 768px) {
    background: linear-gradient(
      180deg,
      transparent 0,
      transparent 50%,
      $light-gray 0,
      $light-gray 80%,
      transparent 80%,
      transparent 100%
    ) !important;

    .img-wrapper {
      width: calc(100vw - #{$content-padding} * 2 - #{$card-padding} * 2);
    }

    &:nth-child(2),
    &:nth-child(5) {
      @include portfolio-item-card($blue);
    }
    &:nth-child(3), &:nth-child(6) {
      @include portfolio-item-card($gray);
    }
    &:nth-child(4) {
      @include portfolio-item-card($red);
    }

    &:nth-child(2n + 1) {
      .portfolio-card {
        margin-left: auto;
      }

      .img-wrapper {
        margin-left: calc(
          -1 * ((100vw - (#{$content-padding} * 2)) * 0.33 - #{$card-padding})
        );
      }
    }
  }
}

.portfolio-card {
  width: 66%;
  margin-bottom: 40px;

  h2 {
    margin-top: -5px;
  }

  h1,
  h2,
  h3,
  button,
  a,
  p {
    color: #fff;
  }

  button {
    border-color: #fff;
  }

  .img-wrapper {
    background: #fff;
    border: 4px solid #fff;
    border-bottom: 0;
    overflow-y: hidden;
    box-shadow: $box-shadow;

    img {
      width: 100%;
      height: auto;
    }
  }

  a {
    display: block;
    margin-top: 20px;
    text-decoration: none;
    width: fit-content;
    @extend button;

    &:hover {
      color: $gray !important;
    }
  }
}
