@import url("https://use.typekit.net/tom2rqb.css");

@import "./variables";
@import "./mixins";

@import "./aboutMe.scss";
@import "./contact.scss";
@import "./footer.scss";
@import "./navigation.scss";
@import "./portfolio.scss";
@import "./services.scss";
@import "./topBanner.scss";

@import "./modal";

body {
  @include eave-medium;
  color: $gray;
  font-size: 20px;
  -webkit-overflow-scrolling: touch;
  background: $white;
}

h1,
h2,
h3 {
  @include acumin;
  color: $gray;
}

h1 {
  font-size: 30px;

  @media screen and (min-width: $content-width) {
    text-align: center;
    font-size: 50px;

    &.section-header {
      margin-top: 150px;
    }
  }
}


button {
  background: transparent;
  border-radius: $border-radius;
  border: 1px solid #fff;

  padding: 5px 15px;
  font-size: 20px;
 
  @include eave-medium;
  margin-right: 10px;

  &:hover {
    cursor: pointer;
  }

  &:active,
  &:focus {
    outline: none;
    cursor: pointer;
  }

  background: linear-gradient(to right, #fff 50%, transparent 50%);
  background-size: 220% 100%;
  background-position: bottom right;
  transition: all 0.4s ease-out;

  &:hover {
    background-position: bottom left;
    color: $gray;
  }
}

a {
  &:focus,
  &:active {
    outline: none;
  }
}

.main-content {
  padding: 0 35px;
}

.hidden {
  display: none;
}

.main {
  margin-top: $header-height;
  @media screen and (max-width: $lg) {
    margin-top: $header-height-xs;
  }
}

:root {
  --animation-duration: 250ms;
  --transition-duration: 250ms;
}


.fade-in {
  opacity: 0;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
  visibility: hidden;
  transform: translateY(10vh);

  &.fast {
    // transition: opacity 0.6s ease-out, transform 0.8s ease-out;
    transform: translateY(5vh);
  }

  &.slow {
    // transition: opacity 0.6s ease-out, transform 2.4s ease-out;
    transform: translateY(15vh);
  }

  &.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
}

// ***** -------- Animation settings / reduced motion -------- ***** \\

/* Contextually shorten duration length */
@media screen and (prefers-reduced-motion: reduce), (update: slow) {
  :root {
    --animation-duration: 0.001ms !important;
    --transition-duration: 0.001ms !important;
  }
}

@media screen and (prefers-reduced-motion: reduce), (update: slow) {
  /* Remove duration for all unknown animation when a user requests a reduced animation experience */
  * {
    animation-duration: var(--animation-duration);
    animation-iteration-count: 1 !important;
    transition-duration: var(--animation-duration);
  }
}

/* Update the duration when animation is critical to understanding and the device can support it */
@media screen and (prefers-reduced-motion: reduce), (update: fast) {
  .c-educational-concept {
    /* Set a new animation duration scoped to this component */
    --animation-duration: 6000ms !important;

    animation-name: educational-concept;
    /* Use the scoped animation duration */
    animation-duration: var(--animation-duration);
  }
}
