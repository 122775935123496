.navigation {
  .toggle-menu-btn {
    @include icon-btn;
    font-size: 30px;
    color: $gray;
  }

  position: relative;

  position: fixed;
  width: 100vw;
  background: #ffffff;
  top: 0;
  left: 0;
  z-index: 15;
  height: $header-height;

  transition: top 0.5s ease-in-out, border 0.7s ease-in-out;

  &.border-bottom {
    box-shadow: $box-shadow;
  }

  &.hide-logo-to-top {
    top: calc((90px + 6px) * -1);

    @media screen and (max-width: $lg) {
      top: 0;
    }
  }

  &.hide-to-top {
    top: calc((#{$header-height} + 6px) * -1);

    @media screen and (max-width: $lg) {
      top: calc((#{$header-height-xs} + 6px) * -1);
    }
  }

  @media screen and (max-width: $lg) {
    height: $header-height-xs;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px $content-padding;
  font-size: 30px;

  img {
    display: none;
    margin-bottom: -10px;
    height: 40px;
    transform: rotate(-10deg);
  }

  .header-text {
    font-size: 50px;
    transform: translateY(-10px);

    .sub-header {
      @include carbon;
      font-size: 12px;
      text-align: right;
      margin-top: -10px;
      margin-right: -30px;

      @-moz-document url-prefix() {
        font-size: 12px;
      }

      @media all and (-webkit-min-device-pixel-ratio: 10000),
        not all and (-webkit-min-device-pixel-ratio: 0) {
        font-size: 12px;
      }
    }

    &:hover {
      color: $red;
      cursor: pointer;
    }
  }

  @media screen and (min-width: $lg) {
    font-size: 20px;
    flex-direction: column;

    padding: 25px $content-padding;

    .header-text {
      font-size: 60px;
      transform: translateY(0px);

      .sub-header {
        font-size: 14px;
      }
    }

    .toggle-menu-btn,
    .placeholder {
      display: none;
    }
  }
}

.navigation-items {
  position: relative;
  display: none;
  @media screen and (min-width: $lg) {
    display: block;
    margin-top: 25px;

    ul {
      display: flex;
      justify-items: flex-end;
      justify-content: center;

      li:not(:last-child) {
        margin-right: 30px;
      }
    }
  }
}

.menu-scrollspy {
  padding: 0;
  margin: 0;

  list-style: none;

  a {
    display: block;
    padding: 10px 20px;
    text-decoration: none;
    color: $gray;
    font-size: 24px;

    &:hover,
    &:active {
      color: lighten($blue, 10);
    }
  }

  .current-section a {
    color: $blue;
    @include eave-medium;
  }
}

.mobile-menu {
  width: 250px;
  position: fixed;
  background: #fff;
  height: 100%;
  top: 0;
  right: 0;
  padding: $content-padding;
  transition: all 0.5s ease-out;

  .toggle-menu-btn {
    float: right;
  }

  &.hide {
    margin-right: -100%;
    transition: all 1s ease-out;
  }

  ul {
    padding-top: 30px;
    a {
      font-size: 2rem;
    }
  }
}

.backdrop {
  &.show {
    position: fixed;
    background: rgba(#000, 0.4);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    transition: all 0.5s ease-out;
  }

  &.hide {
    transition: all 0.5s ease-out;
  }
}

.logo {
  position: absolute;
  height: 150px;
  top: 20px;
  transition: all 0.5s ease-out;

  &.logo-left {
    left: calc((100vw - 500px) / 2 - 100px);
  }

  &.logo-right {
    right: calc((100vw - 500px) / 2 - 100px);
    transform: scaleX(-1);
  }

  @media screen and (max-width: $lg) {
    &.logo-left {
      left: calc((100vw - 182px) / 2 - 90px);
      height: 80px;
      top: 10px;
      transform: rotate(-15deg);
    }

    &.logo-right {
      display: none;
    }
  }

  @media screen and (max-width: 450px) {
    &.logo-left {
      left: calc((100vw - 182px) / 2 - 70px);
    }
  }
}
.navigation.hide-logo-to-top {
  .logo {
    &.logo-left {
      transform: rotate(-10deg);
    }

    &.logo-right {
      transform: scaleX(-1) rotate(-10deg);
    }
    &.logo-left,
    &.logo-right {
      top: 100px;
      height: 80px;
    }

    @media screen and (max-width: $lg) {
      &.logo-left {
        transform: rotate(-15deg);
        top: 10px;
      }
    }
  }
}
