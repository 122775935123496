// ***** -------- Fonts -------- ***** \\

@mixin eave {
  font-family: mr-eaves-modern, sans-serif;
  font-weight: 200;
  font-style: normal;
}

@mixin eave-medium {
  font-family: mr-eaves-modern, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin acumin {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 800;
  font-style: normal;
}

@mixin acumin-medium {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin carbon {
  font-family: t26-carbon, monospace;
  font-weight: 400;
  font-style: normal;
}

//  -------------------------------------------------- //

@mixin icon-btn {
  border: none;
  padding: 0;
}

@mixin card($color) {
  border: $border-thickness-sm solid #fff;
  box-shadow: $box-shadow;
  background: $color;
  color: #fff;
  padding: $card-padding;

  h1,
  h2,
  h3,
  button,
  a {
    color: #fff;
  }

  button {
    border-color: #fff;
  }
}

@mixin portfolio-item-card($color) {
  .portfolio-card {
    @include card($color);
  }
}

@mixin portfolio-card-lg($color) {
  @include card($color);
  margin: 300px $negative-content-padding;
  padding: 30px $content-padding;
  height: 200px;

  .portfolio-card {
    position: relative;
    width: 100%;
    margin: 0 auto;
    width: $content-width;

    .img-wrapper {
      width: 40vw;
      max-width: 700px;
      position: absolute;
      right: calc(#{$card-padding} + #{$content-padding});
      // top: 0;
      transform: translateY(calc(-25px - (100%) / 2)); //100% - 268px
    }

    .portfolio-text {
      width: 30%;
      padding-left: calc(#{$card-padding} + #{$content-padding});
    }
  }

  &:nth-child(2n + 1) {
    .img-wrapper {
      left: calc(#{$card-padding} + #{$content-padding});
    }

    .portfolio-text {
      margin-left: auto;
      padding-left: 0;
      padding-right: calc(#{$card-padding} + #{$content-padding});
    }
  }

  @media screen and (max-width: $content-width) {
    .portfolio-card {
      width: 100%;
    }
  }
}


@mixin banner-height-offset($height, $offset, $image-offset) {
  .background-image {
    transform: translateY(#{$offset});
    height: $height;
    transition: all 0.3s ease-in-out;

    img {
      transform: translateY($image-offset);
    }
  }

  .background-overlay {
    transition: all 0.3s ease-in-out;
    transform: translateY(
      calc(-1 * #{$height} + #{$offset})
    );
    height: $height;
  }

  .banner-card {
    margin-top: calc(-2 * #{$height});
  }
}