.contact {
  margin: 50px 0;
  position: relative;

  .background-image {
    margin: 0 $negative-content-padding;
    transform: translateY(#{$banner-offset});
    height: $banner-height;
    overflow: hidden;

    img {
      width: 100vw;
      height: auto;
    }
  }

  .background-overlay {
    margin: 0 $negative-content-padding;
    transform: translateY(calc(-1 * #{$banner-height} + #{$banner-offset}));
    height: $banner-height;

    background: rgba($red, 0.7);
  }
}

.contact-card {
  @include card($red);
  max-width: 700px;
  transform: translateX(calc(50vw - #{$content-padding})) translateX(-50%);
  margin-top: calc(-2 * #{$banner-height});

  h1 {
    text-align: center;
  }

  label {
    &::after {
      display: inline-block;
      margin-right: 4px;
      color: #fff;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
    }
  }

  input,
  textarea {
    border: 1px solid #fff;
    border-radius: $border-radius;
    background: transparent;
    padding: 5px 10px;
    margin: 5px 0 20px 0;
    color: #fff;
    width: calc(100% - #{$card-padding});

    font-size: 16px;
    @include eave-medium;

    transition: all 0.25s ease-in;

    &::placeholder {
      color: rgba(#fff, 0.5);
    }

    &:focus {
      outline: none;
      background: #fff;
      color: $gray;
    }

    &.invalid {
      border-width: 2px;

      &::placeholder {
      }
    }
  }

  .invalid-text {
    //  color: $gray;
    margin: -15px 0 15px 0;
    font-style: italic;
    font-weight: 500;

    i {
      font-size: 15px;
      margin-right: 5px;
    }
  }

  .direct {
    padding-bottom: 20px;

    .e-mail {
      i {
        margin-right: 10px;
        transform: translateY(4px);
      }

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .button-wrapper {
    text-align: center;
  }
}

.social-btns-container {
  position: absolute;
  background-image: url("../images/banner.png");
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: -1;

  color: #fff;
  font-size: 20px;
  right: $content-padding;
  padding: 10px;
  width: 35px;
  height: 120px;
  padding-bottom: 45px;
  margin-bottom: 20px;

  a {
    height: 30px;
    width: 30px;
    display: block;
    background: #fff;
    color: $red;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: all 0.3s ease-in-out;

    &:hover {
      font-size: 1.8rem;
      color: lighten($red, 10);
      height: 40px;
      width: 40px;
      margin-top: -5px;
      margin-left: -5px;
      transition: all 0.1s ease-in-out;
    }
  }

  @media screen and (min-width: 900px) {
    right: calc((100vw - #{$contact-width}) / 2);
  }
}


.popover {
  @include card($blue);
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    border-radius: 50%;
    border: 1px solid #fff;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  position: fixed;
  z-index: 20;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: -20vh;
  text-align: center;

  $popover-animation: popover-animation 5s ease-out forwards;

  animation: $popover-animation;
  -webkit-animation: $popover-animation;
  -moz-animation: $popover-animation;
  -o-animation: $popover-animation;
}

@keyframes popover-animation {
  0% {
    margin-bottom: -10vh;
  }
  10% {
    margin-bottom: 0vh;
  }
  90% {
    margin-bottom: 0vh;
  }
  100% {
    margin-bottom: -10vh;
  }
}
